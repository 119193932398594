@import url("https://fonts.googleapis.com/css2?family=Exo+2&display=swap");

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Exo 2", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000000;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

a {
  text-decoration: none;
}

p {
  font-size: larger;
}

p.button {
  background-color: #000000;
  text-align: center;
  max-width: 200px;
  border-radius: 5px;
  padding: 15px;
  display: block;
  margin: auto;
}

p.button > a {
  color: #d0d0d0;
  font-weight: 600;
}

p.button:hover > a {
  color: #929292;
}

p.button:active > a {
  color: #323031;
}

.navbar-container {
  background-size: cover;
  width: 100%;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.links-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 3px 15px;
  gap: 25px;
  background-color: #000000;
}

.link-active {
  color: #d0d0d0;
  text-decoration: none;
  font-size: larger;
  border-radius: 3px;
  padding: 10px 20px;
}

.link-inactive {
  color: #929292;
  text-decoration: none;
  font-size: larger;
  border-radius: 3px;
  padding: 10px 20px;
}

.page-header {
  display: block;
  margin: auto;
}

.page-container {
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 35px;
}

/* Only displays 1 element per row */
.media-list-column {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}

/* Adapts to width of screen, allows side-by-side elements in rows */
.media-list-flex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.media-container {
  max-width: 80vw;
  margin: 10px;
  padding: 25px;
  border-radius: 4px;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  color: #c1bdb3;
  line-height: 24px;
}

.media-container * {
  max-width: 70vw;
  max-height: 500px;
  text-decoration: none;
  color: #c1bdb3;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 25px;
}

/* Mobile Only Styling */
@media only screen and (max-width: 600px) {
  .links-container {
    justify-content: center;
  }

  .media-container {
    max-width: 90vw;
  }
  .media-container * {
    max-width: 80vw;
  }
}
